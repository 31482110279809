export class FxRate {
    origin: string;
    IDR: number;
    MYR: number;
    SGD: number;
    HKD: number;
    PHP: number;
    USD: number;

    constructor(ori: string, idr: number, myr: number, sgd: number, hkd: number, php: number, usd: number) {
        this.origin = ori;
        this.MYR = myr;
        this.SGD = sgd;
        this.IDR = idr;
        this.HKD = hkd;
        this.PHP = php;
        this.USD = usd;
    }
}
