import { Component, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';

import { User, Member } from '../../_models';
import { UserService, AuthenticationService, MemberService, CurrencyService } from '../../_services';
import { DateTimeFormatPipe } from '../../_pipes/datetime.pipe';
import { SlideInOutAnimation  } from '../../_animations';

@Component({
    templateUrl: 'searchresult.component.html',
    selector: 'searchresult',
    animations: [SlideInOutAnimation]
})

export class SearchResultComponent {
	@Input() member:any;
	showDetails = false;

	constructor(private ccyService: CurrencyService) {}

  ngOnInit() {
    this.member.scheme.copay = this.ccyService.convertCurrencies(this.member.currency.code, this.member.scheme.copay);
    this.member.scheme.capping = this.ccyService.convertCurrencies(this.member.currency.code, this.member.scheme.capping);

  }


	toggleViewDetails() {
		this.showDetails = ! this.showDetails;
	}

	prettyPrint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
