import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { map,timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DateFormatPipe } from '../_pipes/date.pipe';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,private datepipe:DateFormatPipe, private router: Router) { }
    login(username: string, password: string) {
        let requestName="authenticateRequest";
        let payload={};
        payload={"username":username,"password":password};
        return this.http.post<any>(`${environment.apiUrl}/api/login`, payload)
            .pipe(map(data => {
                if(data && data.loginStatus &&
                        data.loginStatus === 'Successful' &&
                        data.jwt) {
                    const userData = {username: username};
                    localStorage.setItem('currentUser', JSON.stringify(userData));
                    localStorage.setItem('jwt', data.jwt);
                }
                return data;
            }),timeout(environment.serverTimeout));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('jwt');
        this.router.navigate(['/login']);;
    }
}
    
 
