import {Component, OnInit} from '@angular/core';
import {first, timeout} from 'rxjs/operators';
import {environment, errorMsg} from '../../environments/environment';
import {Member, User} from '../_models';
import {AuthenticationService, MemberService, UserService} from '../_services';
import {DateTimeFormatPipe} from '../_pipes/datetime.pipe';
import {TimeoutError} from 'rxjs';

@Component({templateUrl: 'home.component.html'})

export class HomeComponent implements OnInit {
    currentUser: User;
    users: User[] = [];
    selectedCountry: string = '-1';
    patientIdn = '';
    memberList: Member[];
    loading: boolean;
    showResults: boolean;
    showError = false;
    errorMsg: string;
    noMemberMsg = errorMsg.memberNotFound;


    constructor(private userService: UserService,
                private authService: AuthenticationService,
                private memberService: MemberService,
                private datetimepipe: DateTimeFormatPipe) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
    }

    deleteUser(id: number) {
        this.userService.delete(id).pipe(first()).subscribe(() => {
            this.loadAllUsers();
        });
    }

    private loadAllUsers() {
        this.userService.getAll().pipe(first()).subscribe(users => {
            this.users = users;
        });
    }

    verifyMember() {
        this.showError = false;
        if (!this.patientIdn || this.patientIdn.trim().length <= 0) {
            this.displayError('Please enter identification number');
            return;
        }

        if (this.selectedCountry === '-1') {
            this.displayError('Please select country');
            return;
        }
        let payload: any = {};
        let visitDate = this.datetimepipe.transform(new Date());
        const benefitTypeMap = {
            SG: ['001', '003', '004'],
            SGAIA: ['001', '003', '004','011'],
            ID: ['001', '003', '004'],
            HK: '1',
            PH: [1],
            MY: [1, 3],
        };
        let body = {
            benefitTypes: benefitTypeMap[this.selectedCountry],
            idn: this.patientIdn,
            visitDateTimeStr: visitDate,
            country: this.selectedCountry
        };
        payload = body;
        payload.country = this.selectedCountry;
        this.memberList = [];
        this.loading = true;
        this.memberService.verify(payload).pipe(first()
            , timeout(environment.serverTimeout))
            .subscribe(data => {
                this.loading = false;
                this.showResults = true;
                if (data && data.memberList
                    && data.memberList.length > 0) {
                    this.memberList = data.memberList;
                }
            }, error => {
                console.log(error);
                if (error instanceof TimeoutError) {
                    this.noMemberMsg = errorMsg.timeoutError;
                } else {
                    this.noMemberMsg = errorMsg.memberNotFound;
                }
                this.loading = false;
                this.showResults = true;
            });
    }

    displayError(msg: string) {
        this.showError = true;
        this.errorMsg = msg;
    }
}
