import {Injectable} from '@angular/core';
import {FxRate} from '../_models';

@Injectable()
export class CurrencyService {
    fxList: FxRate[];

    constructor() {
        this.fxList = [];
        this.fxList.push(new FxRate('SGD', 10561.8,3.10708,1,5.76401,38.5605,1.3577));
        this.fxList.push(new FxRate('IDR', 1,0.00029,0.00009,0.00055,0.00365,14342.1));
        this.fxList.push(new FxRate('MYR', 3394.68,1,0.32136,1.85261,12.3937,4.21915));
        this.fxList.push(new FxRate('HKD', 1832.27,0.53902,0.17345,1,6.68947,7.82704));
        this.fxList.push(new FxRate('PHP', 273.593,0.08049,0.0259,0.14931,1,52.3619));
        this.fxList.push(new FxRate('USD', 14342.1,4.21915,1.3577,7.82704,52.3619,1));
    }

    convertCurrencies(originCCY: string, amt: number): FxRate {
        for (const f of this.fxList) {
            if (f.origin === originCCY) {
                const result = new FxRate(f.origin, this.roundNumbers(f.IDR * amt),
                    this.roundNumbers(f.MYR * amt),
                    this.roundNumbers(f.SGD * amt),
                    this.roundNumbers(f.HKD * amt),
                    this.roundNumbers(f.PHP * amt),
                    this.roundNumbers(f.USD * amt));
                return result;
            }
        }
    }

    roundNumbers(f: number): number {
        return parseFloat(f.toFixed(2));
    }

}
