import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User,RegisterResponse } from '../_models';

@Injectable()
export class MemberService {
	constructor(private http: HttpClient) { }

	verify(payload:any){
         return this.http.post<any>(`${environment.apiUrl}/api/verify`, payload);
	}
}
