import { Component,OnInit,ChangeDetectorRef } from '@angular/core';
import { User } from './_models';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent {
	currentUser:User;

	ngOnInit(){
		this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
	}
	getLoggedIn(){
		try{
			this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
		}finally{
			return this.currentUser;
		}
	}

	constructor(private cdRef:ChangeDetectorRef) {}

	ngAfterViewChecked()
	{
	  this.getLoggedIn();
	  this.cdRef.detectChanges();
	}
 }
